import React from "react"
import { MDBContainer, MDBRow, MDBCol, MDBBox, MDBCarousel, MDBCarouselInner, MDBCarouselItem } from "mdbreact"
import Layout from "../components/TLCLayout"
import bc08Image from "../images/services/coatings/modesta-bc-08.png"
import bc05Image from "../images/services/coatings/modesta-bc-05.png"
import plImage from "../images/services/coatings/modesta-pd.png"
import bc06Image from "../images/services/coatings/modesta-bc-06.png"
import lpsImage from "../images/services/coatings/modesta-lps.png"

var coatingImageStyle = {
    marginLeft: 'auto',
    marginRight: 'auto'
}

var coatingRow = {
    marginTop: '1.5em'
}

var priceText = {
    fontWeight: '800'
}

const CeramicCoatingsServicesPage = () => (
    <Layout heroTitle="Services" heroImageUrl="./images/hero-dark.jpg">
        <MDBContainer style={{paddingTop: '3em'}}>
            <MDBRow style={{paddingTop: '1em'}}>
                <MDBCol>
                    <h3>Modesta Ceramic Coatings</h3>
                    <p>Long term paint coatings are a great way to keep your vehicle protected with the latest ceramic  technology.  Coatings offer more chemical protection from bird droppings, hard water, and bug guts than any waxes or sealants of the past.  This protection also lasts for years instead of just weeks or months.  Finally, long term paint coatings require little more than regular car washes to keep your vehicle looking like new for years.  Check out the information on our Modesta Coatings below, and contact us to get your vehicle protected today!</p>
                </MDBCol>
            </MDBRow>
            <MDBRow style={coatingRow}>
                <MDBCol md="4">
                    <MDBBox display="flex" justifyContent="center" ><img style={coatingImageStyle} src={bc08Image} /></MDBBox>
                </MDBCol>
                <MDBCol md="8">
                    <MDBRow>
                        <MDBCol>
                            <h4>BC-08 Neosilica Matrix Coating 3+ Years</h4>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol>
                            <p>Modesta BC-08 Neosilica Matrix Coating is a fantastic entry-level coating from Modesta that competes with the premium coating offerings from many other manufacturers. BC-08 is a fantastic way to protect your daily driven vehicle from environmental hazards while also keeping it looking great, and easing maintenance. Perfect for a vehicle that is only going to be leased for a few years, or an older vehicle that may already be a little less than perfect. With Modesta BC-08 you can get into a high quality paint coating without the premium price tag.</p>
                            <p><span style={priceText}>Starting at $995</span> (plus Decontamination Prep Detail or Paint Correction Detail)</p>
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
            </MDBRow>           
            <MDBRow style={coatingRow}>
                <MDBCol md="4">
                    <MDBBox display="flex" justifyContent="center" ><img style={coatingImageStyle} src={bc05Image} /></MDBBox>
                </MDBCol>                    
                <MDBCol md="8">
                    <MDBRow>
                        <MDBCol>
                            <h4>BC-05 Advanced Water Repellent Coating 7+ Years</h4>
                        </MDBCol>
                    </MDBRow> 
                    <MDBRow>
                        <MDBCol>
                            <p>Modesta BC-05 is the flagship product from Modesta. This coating has an expected lifespan of over 7 years with proper maintenance. This is a premium paint coating that has yet to be matched in protection, gloss, or water repellency by other manufacturers. This coating provides amazing chemical protection, a self cleaning effect, and amazing luster to keep your vehicle protected and looking amazing for many, many years. The inorganic nature of this coating also makes it immune to oxidation and makes it resistant to temperatures up to 900*C.</p>
                            <p><span style={priceText}>Starting at $1595</span> (A Paint Correction Detail is required to prep surface for application of this professional grade coating.)</p>
                        </MDBCol>
                    </MDBRow>
                </MDBCol>            
            </MDBRow>  
            <MDBRow style={coatingRow}>
                <MDBCol md="4">
                    <MDBBox display="flex" justifyContent="center" ><img style={coatingImageStyle} src={plImage} /></MDBBox>
                </MDBCol>
                <MDBCol md="8">
                    <MDBRow>
                        <MDBCol>
                            <h4>Private Label Coating</h4>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol>
                            <p>The very best Modesta has to offer. Private Label combines the advantages of both organic and inorganic technologies, thus delivering unprecedented shine, protection, resistance and self-cleaning effect.</p>
                            <p>Comprised of a thick, chemically resistant base layer, and a super hydrophobic organic top coat, Modesta Private Label offers the thickest, most protective, and best performing ceramic coating available.</p>
                            <p><span style={priceText}>Starting at $2495</span> (A Paint Correction Detail is required to prep surface for application of this professional grade coating.)</p>
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
            </MDBRow>        
            <MDBRow style={coatingRow}>
                <MDBCol md="4">
                    <MDBBox display="flex" justifyContent="center" ><img style={coatingImageStyle} src={bc06Image} /></MDBBox>
                </MDBCol>                  
                <MDBCol md="8">
                    <MDBRow>
                        <MDBCol>
                            <h4>BC-06 High Temperature Wheel Coating</h4>
                        </MDBCol>
                    </MDBRow>    
                    <MDBRow>
                        <MDBCol>
                            <p>Modesta BC-06 is a sprayable paint coating that uses its tight molecular structure to give amazing high temperature protection to your wheels and painted brake calipers. The incredible chemical protection will help prevent hot, corrosive brake dust from etching the surface of your calipers and wheels. With this coating installed, your wheels and calipers will be incredibly easy to clean and will stay cleaner longer. This coating can be installed to just the faces of the wheels while they are on the vehicle, or we can remove the wheels to coat the calipers, inner wheel barrels, and wheel faces.</p>
                            <p><span style={priceText}>Starting at $300</span> for Coating Wheel Faces</p>
                            <p><span style={priceText}>Starting at $600</span> for Removing Wheels and Coating Calipers, Inner Wheel Barrels, and Wheel Faces</p>                            
                        </MDBCol>                        
                    </MDBRow>                     
                </MDBCol>              
            </MDBRow> 
            <MDBRow style={coatingRow}>
                <MDBCol md="4">
                    <MDBBox display="flex" justifyContent="center" ><img style={coatingImageStyle} src={lpsImage} /></MDBBox>
                </MDBCol>
                <MDBCol md="8">
                    <MDBRow>
                        <MDBCol>
                            <h4>Leather Protection System</h4>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol>
                            <p>The Modesta Leather Protection System two-part process designed to give a level of protection to your interior leather that hasn’t been available before. The Modesta Leather Protection System offers protection from liquid penetration as well as abrasion protection on heavily trafficked areas like raised seat bolsters. With this system applied, your leather will be easier to maintain, less prone to staining, and less likely to wear.</p>
                            <p><span style={priceText}>Starting at $595</span> (includes interior detail and prep)</p>
                        </MDBCol>
                    </MDBRow>                    
                </MDBCol>
            </MDBRow>                                               
        </MDBContainer>
    </Layout>
)

export default CeramicCoatingsServicesPage